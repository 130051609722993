import './index.scss';


// images
// list only those files which are not used directly in CSS

import './images/logo.svg';
import './images/infographics.svg';

import './images/cooperation/fnusa-icrc.png';
import './images/cooperation/fn-brno.png';
import './images/cooperation/munimed.png';
import './images/cooperation/munimed-iba.png';
import './images/cooperation/cps-jep.png';
import './images/cooperation/cns-jep.png';
import './images/cooperation/uzis-cr.png';
import './images/cooperation/fnusa.png';
import './images/cooperation/ceitec-vutbr.png';
import './images/cooperation/mzcr.png';
import './images/cooperation/jmk.png';
import './images/cooperation/brno.png';

import './images/partners/microsoft.png'
import './images/partners/csystem.png'
import './images/partners/soitron.png'


// assets
// @todo: add pdf loader
// import './assets/doporuceni-cls.pdf';
// import './assets/doporuceni-lf-mu.pdf';
// import './assets/doporuceni-mp.pdf';
// import './assets/doporuceni-rada-vlády-pro-seniory.pdf';
// import './assets/vypis-z-or.pdf';
